import React from "react";

import EmailIcon from "components/Icons/share/email.svg";

import ShareButton from "../ShareButton";

type Props = { url: string; title: string };

export const EmailShareLink: React.FC<React.PropsWithChildren<Props>> = ({
  url,
  title,
  children,
}) => {
  const shareUrl = `mailto:?subject=Check out this article from Leafly: ${encodeURIComponent(
    title,
  )}&body=${url}`;
  return (
    <ShareButton aria-label="Share through email" href={shareUrl}>
      {children}
    </ShareButton>
  );
};

export default (props: Props) => {
  return (
    <EmailShareLink {...props}>
      <EmailIcon height="16" width="16" />
    </EmailShareLink>
  );
};
