import React from "react";
import Head from "next/head";
import Script from "next/script";

import publicConfig from "config/public";
import useDomain from "hooks/useDomain";

type Props = {
  categories?: string[];
  author?: string;
};

export const ChartBeat: React.FC<Props> = ({
  categories = [],
  author = "",
}) => {
  const domain = useDomain();

  if (!publicConfig.chartbeat.enabled) {
    return null;
  }

  const categoriesList = categories.join(",");

  return (
    <>
      <Head>
        <link rel="dns-prefetch" href="https://static.chartbeat.com" />
      </Head>
      <Script id="chartbeat-start">
        {`
        var _sf_startpt = (new Date()).getTime();
      `}
      </Script>
      <Script
        id="chartbeat-init"
        dangerouslySetInnerHTML={{
          __html: `
        (function() {
          var _sf_async_config = window._sf_async_config = (window._sf_async_config || {});

          _sf_async_config.uid = 34276;
          _sf_async_config.domain = '${domain.replace("https://", "")}';
          _sf_async_config.useCanonical = true;
          _sf_async_config.sections = '${categoriesList}';
          _sf_async_config.authors = '${author || ""}';

          function loadChartbeat() {
            var e = document.createElement('script');
            var n = document.getElementsByTagName('script')[0];
            e.type = 'text/javascript';
            e.async = true;
            e.src = '//static.chartbeat.com/js/chartbeat.js';
            n.parentNode.insertBefore(e, n);
          }
          loadChartbeat();
        })();
      `,
        }}
      ></Script>
    </>
  );
};
