import React, { ReactNode } from "react";
import isEmpty from "lodash/isEmpty";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";

import { RegionRestriction } from "custom-types/Wordpress";

import { ChartBeat } from "components/news/Chartbeat/Chartbeat";
import { NewsMeta } from "components/news/NewsMeta/NewsMeta";
import NewsGlobalStyles from "components/NewsGlobalStyles/NewsGlobalStyles";

// import OneSignal from "components/OneSignal";
import ArrowSprite from "../ArrowSprite";

type Props = {
  authorName?: string;
  categories?: string[];
  children: ReactNode;
  currentLetter?: string;
  currentPage?: number;
  id: number;
  primaryCategorySlug?: string | null;
  regionRestriction: keyof typeof RegionRestriction;
  schemaJson?: string;
  scripts?: string[];
  seoHeadContent: string;
  slug: string;
  styles?: string[];
  tags?: string[];
  title: string;
  totalPages?: number;
};

const NewsRequiredContainer: React.FC<Props> = ({
  authorName,
  categories = [],
  children,
  currentLetter,
  currentPage,
  id,
  regionRestriction,
  schemaJson = "",
  scripts = [],
  seoHeadContent,
  styles = [],
  title,
  totalPages,
}) => {
  const { asPath } = useRouter();
  const isPreview = asPath.indexOf("/wp/preview") > -1;

  return (
    <>
      <Head>
        {styles.length > 0 &&
          styles.map((style, i) => (
            <link key={`news-page-style-${i}`} href={style} rel="stylesheet" />
          ))}
      </Head>

      {scripts.length > 0 &&
        scripts.map((script) => (
          <Script
            key={script}
            src={script}
            strategy={
              script.includes("jquery")
                ? "beforeInteractive"
                : "afterInteractive"
            }
          />
        ))}
      <ArrowSprite />

      {isPreview ? (
        <Head>
          <title>PREVIEW | {title}</title>
        </Head>
      ) : (
        <>
          <NewsMeta
            articleId={id}
            regionRestriction={regionRestriction}
            seoHeadContent={seoHeadContent}
            title={title}
            currentPage={currentPage}
            totalPages={totalPages}
            currentLetter={currentLetter}
          />
          {/* Disabled 3/20/23 https://leafly.atlassian.net/browse/B2C-565
            <OneSignal /> 
          */}
          <ChartBeat categories={categories} author={authorName} />
        </>
      )}

      {children}

      {!isEmpty(schemaJson) && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: schemaJson }}
        />
      )}
      <NewsGlobalStyles />
    </>
  );
};

export default NewsRequiredContainer;
