// eslint-disable-next-line no-restricted-imports -- Required to parse complex HTML from WP
import { DOMNode, Element } from "html-react-parser";

export default (domNode: DOMNode, page: number) => {
  const { attribs, name } = domNode as Element;

  if (name === "link" && attribs?.rel === "canonical") {
    (domNode as Element).attribs.href = `${
      (domNode as Element).attribs.href
    }/page/${page}`;
  }
  if (name === "script") {
    // @ts-ignore
    const json = JSON.parse((domNode as Element).children[0].data);

    if (json?.["@graph"] && Array.isArray(json?.["@graph"])) {
      const collectionPageIndex = json["@graph"].findIndex(
        (item: Record<string, string>) => item["@type"] === "CollectionPage",
      );

      if (collectionPageIndex > -1) {
        if (json["@graph"][collectionPageIndex].url) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][collectionPageIndex].url = `${json["@graph"][collectionPageIndex].url}/page/${page}`;
        }
        if (json["@graph"][collectionPageIndex].primaryImageOf?.["@id"]) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][collectionPageIndex].primaryImageOf["@id"] = `${json["@graph"][collectionPageIndex].url}/page/${page}#primaryimage`;
        }
        if (json["@graph"][collectionPageIndex].image?.["@id"]) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][collectionPageIndex].image["@id"] = `${json["@graph"][collectionPageIndex].image["@id"]}/page/${page}#primaryimage`;
        }
        if (json["@graph"][collectionPageIndex].breadcrumb?.["@id"]) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][collectionPageIndex].breadcrumb["@id"] = `${json["@graph"][collectionPageIndex].breadcrumb["@id"]}/page/${page}#breadcrumb`;
        }
      }
    }

    const imageObjectIndex = json["@graph"].findIndex(
      (item: Record<string, string>) => item["@type"] === "ImageObject",
    );

    if (imageObjectIndex > -1) {
      if (json["@graph"]?.[1]?.["@type"] === "ImageObject") {
        // eslint-disable-next-line prettier/prettier -- ignoring for readability
        json["@graph"][1]["@id"] = `${json["@graph"][1]["@id"]}/page/${page}#primaryimage`;
      }
    }

    // @ts-ignore
    (domNode as Element).children[0].data = JSON.stringify(json);
  }
};
