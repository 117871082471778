import React from "react";

import PinterestIcon from "components/Icons/share/pinterest.svg";

import ShareButton from "../ShareButton";

type Props = { url: string };

export const PinterestShareLink: React.FC<React.PropsWithChildren<Props>> = ({
  url,
  children,
}) => {
  const shareUrl = `https://www.pinterest.com/pin/create/link/?url=${url}`;
  return (
    <ShareButton aria-label="Share on pinterest" href={shareUrl}>
      {children}
    </ShareButton>
  );
};

export default (props: Props) => {
  return (
    <PinterestShareLink {...props}>
      <PinterestIcon height="16" width="16" />
    </PinterestShareLink>
  );
};
