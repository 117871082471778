import React from "react";

import XIcon from "components/Icons/share/x.svg";

import ShareButton from "../ShareButton";

type Props = { url: string; title: string };

export const XShareLink: React.FC<React.PropsWithChildren<Props>> = ({
  url,
  title,
  children,
}) => {
  const shareUrl = `https://x.com/intent/tweet?via=leafly&text=${encodeURIComponent(
    title,
  )}&url=${url}`;
  return (
    <ShareButton aria-label="Share on x" href={shareUrl}>
      {children}
    </ShareButton>
  );
};

export default (props: Props) => {
  return (
    <XShareLink {...props}>
      <XIcon height="16" width="16" />
    </XShareLink>
  );
};
