import React from "react";
import classNames from "classnames";

import useDomainCountryCode from "hooks/useDomainCountryCode";

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedInShareButton,
  PinterestShareButton,
  XShareButton,
} from "components/ShareButton";
import ClipboardButton from "components/ShareButton/ClipboardButton";

const NewsSocialShareBar: React.FC<{
  link: string;
  title: string;
  className?: string;
}> = ({ title, link, className }) => {
  const isCanada = useDomainCountryCode() === "CA";
  const baseUrl = isCanada ? "www.leafly.ca" : "www.leafly.com";
  const pagePath = baseUrl + link;
  const base_utm = "?utm_medium=social-org&utm_campaign=share-icon";
  return (
    <div className={classNames(className, "flex gap-x-2 text-white")}>
      <FacebookShareButton
        url={pagePath + encodeURIComponent(base_utm + "&utm_source=fb")}
      />
      <XShareButton
        url={pagePath + encodeURIComponent(base_utm + "&utm_source=x-twitter")}
        title={title}
      />
      <LinkedInShareButton
        url={pagePath + encodeURIComponent(base_utm + "&utm_source=linkedin")}
      />
      <PinterestShareButton
        url={pagePath + encodeURIComponent(base_utm + "&utm_source=pinterest")}
      />
      <EmailShareButton url={pagePath} title={title} />
      <ClipboardButton url={pagePath} />
    </div>
  );
};

export default NewsSocialShareBar;
