import React from "react";

import { SnackBarMessages, useSnackBar } from "context/SnackBarContext";

import PaperclipIcon from "components/Icons/paperclip.svg";

import ShareButton from "./ShareButton";

type Props = { url: string };

export const useCopyLinkToClipboard = (text: string) => {
  const { addSnackBarItem } = useSnackBar();

  return () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        addSnackBarItem(SnackBarMessages.linkCopiedToClipboard);
      })
      .catch((_err) => {
        addSnackBarItem(SnackBarMessages.linkCopiedToClipboardError);
      });
  };
};

export const CopyToClipboardLink: React.FC<React.PropsWithChildren<Props>> = ({
  url,
  children,
  ...otherProps
}) => {
  const copyToClipboard = useCopyLinkToClipboard(url);
  return (
    <ShareButton
      onClick={() => copyToClipboard()}
      role="button"
      aria-label="Copy link to clipboard"
      {...otherProps}
    >
      {children}
    </ShareButton>
  );
};

export default (props: Props) => (
  <CopyToClipboardLink {...props} data-testid="copy-to-clipboard-button">
    <PaperclipIcon height="16" width="16" />
  </CopyToClipboardLink>
);
