/* eslint-disable no-useless-escape -- update to next 14.2 caused issues with xmlns on line 380 */

// These styles are individually imported based on the current active editor blocks.
// You can see the active/inactive blocks in this Wordpress settings page (admin access required).
// https://www.leafly.com/wp-admin/options-general.php?page=disable-blocks
// import "@wordpress/block-library/build-style/button/style.css"; decided to hard code
// import "@wordpress/block-library/build-style/buttons/style.css"; decided to hard code
import "@wordpress/block-library/build-style/cover/style.css";
import "@wordpress/block-library/build-style/details/style.css";
import "@wordpress/block-library/build-style/embed/style.css";
import "@wordpress/block-library/build-style/footnotes/style.css";
import "@wordpress/block-library/build-style/gallery/style.css";
import "@wordpress/block-library/build-style/group/style.css";
import "@wordpress/block-library/build-style/heading/style.css";
import "@wordpress/block-library/build-style/image/style.css";
import "@wordpress/block-library/build-style/list/style.css";
import "@wordpress/block-library/build-style/media-text/style.css";
import "@wordpress/block-library/build-style/paragraph/style.css";
import "@wordpress/block-library/build-style/preformatted/style.css";
import "@wordpress/block-library/build-style/pullquote/style.css";
import "@wordpress/block-library/build-style/quote/style.css";
import "@wordpress/block-library/build-style/separator/style.css";
import "@wordpress/block-library/build-style/spacer/style.css";
import "@wordpress/block-library/build-style/table/style.css";

import React from "react";

const NewsGlobalStyles: React.FC<{ children?: React.ReactNode }> = () => {
  return (
    <>
      <style jsx global>{`
        --scoped-blocks: .wp-block-leafly-blocks-leafly-dispensary-card,
          .wp-block-leafly-blocks-leafly-star-rating;

        /**
         * Utility color, font size, alignment classes
         */
        .has-black-color {
          color: var(--color-default) !important;
        }
        .has-black-background-color {
          background-color: var(--color-default) !important;
        }
        .has-dark-grey-color {
          color: #888 !important;
        }
        .has-dark-grey-background-color {
          background-color: #888 !important;
        }
        .has-grey-color {
          color: var(--color-grey) !important;
        }
        .has-grey-background-color {
          background-color: var(--color-grey) !important;
        }
        .has-light-grey-color {
          color: var(--color-light-grey) !important;
        }
        .has-light-grey-background-color {
          background-color: var(--color-light-grey) !important;
        }
        .has-leafly-white-color {
          color: var(--color-leafly-white) !important;
        }
        .has-leafly-white-background-color {
          background-color: var(--color-leafly-white) !important;
        }
        .has-white-color {
          color: var(--color-white) !important;
        }
        .has-white-background-color {
          background-color: var(--color-white) !important;
        }
        .has-dark-green-color {
          color: var(--color-primary) !important;
        }
        .has-dark-green-background-color {
          background-color: var(--color-primary) !important;
        }
        .has-green-color {
          color: var(--color-green) !important;
        }
        .has-green-background-color {
          background-color: var(--color-green) !important;
        }
        .has-light-green-color {
          color: var(--color-tertiary) !important;
        }
        .has-light-green-background-color {
          background-color: var(--color-tertiary) !important;
        }
        .has-lighter-green-color {
          color: #ccdad7 !important;
        }
        .has-lighter-green-background-color {
          background-color: #ccdad7 !important;
        }
        .has-lightest-green-color {
          color: #e6f2f0 !important;
        }
        .has-lightest-green-background-color {
          background-color: #e6f2f0 !important;
        }
        .has-yellow-color {
          color: var(--color-yellow) !important;
        }
        .has-yellow-background-color {
          background-color: var(--color-yellow) !important;
        }
        .has-caryophyllene-color {
          color: var(--color-caryophyllene) !important;
        }
        .has-caryophyllene-background-color {
          background-color: var(--color-caryophyllene) !important;
        }
        .has-humulene-color {
          color: var(--color-humulene) !important;
        }
        .has-humulene-background-color {
          background-color: var(--color-humulene) !important;
        }
        .has-limonene-color {
          color: var(--color-limonene) !important;
        }
        .has-limonene-background-color {
          background-color: var(--color-limonene) !important;
        }
        .has-linalool-color {
          color: var(--color-linalool) !important;
        }
        .has-linalool-background-color {
          background-color: var(--color-linalool) !important;
        }
        .has-myrcene-color {
          color: var(--color-myrcene) !important;
        }
        .has-myrcene-background-color {
          background-color: var(--color-myrcene) !important;
        }
        .has-ocimene-color {
          color: var(--color-ocimene) !important;
        }
        .has-ocimene-background-color {
          background-color: var(--color-ocimene) !important;
        }
        .has-pinene-color {
          color: var(--color-pinene) !important;
        }
        .has-pinene-background-color {
          background-color: var(--color-pinene) !important;
        }
        .has-terpinolene-color {
          color: var(--color-terpinolene) !important;
        }
        .has-terpinolene-background-color {
          background-color: var(--color-terpinolene) !important;
        }
        .has-error-color {
          color: var(--color-error) !important;
        }
        .has-error-background-color {
          background-color: var(--color-error) !important;
        }
        .has-pickup-color {
          color: var(--color-pickup) !important;
        }
        .has-pickup-background-color {
          background-color: var(--color-pickup) !important;
        }
        .has-normal-font-size,
        .has-huge-font-size {
          font-size: initial !important;
        }
        .has-extra-extra-large-font-size {
          font-size: var(--font-size-xxl) !important;
        }
        .has-extra-large-font-size {
          font-size: var(--font-size-xl) !important;
        }
        .has-large-font-size {
          font-size: var(--font-size-l) !important;
        }
        .has-medium-font-size {
          font-size: var(--font-size-m) !important;
        }
        .has-small-font-size {
          font-size: var(--font-size-s) !important;
        }
        .has-extra-small-font-size {
          font-size: var(--font-size-xs) !important;
        }
        .has-text-align-right {
          text-align: right;
        }
        .has-text-align-center {
          text-align: center;
        }
        .has-text-align-left {
          text-align: left;
        }

        /**
         * Base font sizing, spacing
         */
        #article-content p,
        #article-content ul:not(.carousel-list, .dropdown-menu),
        #article-content ol {
          font-size: var(--font-size-m);
          max-width: 768px;
        }
        #article-content *:not(var(--scoped-blocks)) p + h2,
        #article-content *:not(var(--scoped-blocks)) p + h3,
        #article-content *:not(var(--scoped-blocks)) p + h4,
        #article-content *:not(var(--scoped-blocks)) p + h5,
        #article-content *:not(var(--scoped-blocks)) p + h6 {
          margin-top: 24px;
        }
        #article-content *:not(var(--scoped-blocks)) h2 + p,
        #article-content *:not(var(--scoped-blocks)) h3 + p,
        #article-content *:not(var(--scoped-blocks)) h4 + p,
        #article-content *:not(var(--scoped-blocks)) h5 + p,
        #article-content *:not(var(--scoped-blocks)) h6 + p {
          margin-top: 12px;
        }
        #article-content p:empty,
        #article-content *:not(var(--scoped-blocks)) span:empty {
          display: none;
        }
        #article-content p.has-background,
        #article-content .wp-block-group.has-background {
          padding: 36px;
        }
        #article-content ul:not(.carousel-list, .interactive-map ul),
        #article-content ol {
          margin-left: 24px;
          margin-bottom: 16px;
          margin-top: 16px;
        }
        #article-content ul:not(.carousel-list, .interactive-map ul) {
          list-style: disc;
          overflow-wrap: break-word;
        }
        #article-content ul:not(.carousel-list).has-background {
          margin-left: 0;
        }
        #article-content ol {
          list-style: decimal;
        }

        /**
         * Dropcap
         */
        .leafly-green-dropcap,
        .has-drop-cap:not(:focus):first-letter {
          font-size: 95px;
          line-height: 84px;
          color: var(--color-green);
          margin: 0px 8px 0 -4px;
          font-weight: 600;
        }
        .leafly-green-dropcap {
          float: left;
        }

        /*
         * Styles for the series listing at the bottom of articles
         */
        .series-active-bar {
          width: 16px;
        }

        /*
         * Contact Form 7 plugin
         */
        .wpcf7-form input[type="text"],
        .wpcf7-form input[type="email"] {
          background-color: #ffffff;
        }
        .wpcf7-form textarea {
          box-sizing: border-box;
          padding: 10px;
          width: 100%;
        }
        .wpcf7-form textarea::-webkit-input-placeholder {
          font-size: 16px;
          color: #017c6b;
        }
        .wpcf7-form textarea::-ms-input-placeholder {
          font-size: 16px;
          color: #017c6b;
        }
        .wpcf7-form textarea::placeholder {
          font-size: 16px;
          color: #017c6b;
        }
        .wpcf7-form input[type="email"],
        .wpcf7-form input[type="text"],
        .wpcf7-form textarea {
          background-color: var(--color-leafly-white);
          margin-bottom: 12px;
        }
        .wpcf7-form span.wpcf7-list-item {
          display: flex;
          margin: 0;
        }
        .wpcf7-form input[type="radio"] {
          width: 20px;
          margin: 7px 7px 0 0;
        }
        .wpcf7-form .wpcf7-submit {
          background-color: var(--color-green);
          border-radius: 4px;
          color: white;
          font-weight: 600;
        }

        /*
         * block quote
         */
        blockquote.wp-block-quote {
          display: block;
          background-color: var(--color-yellow);
          font-weight: 600;
          margin: 12px 0;
          padding: 16px;
          text-align: left;
        }
        blockquote.wp-block-quote p {
          font-size: var(--font-size-m);
          font-style: normal;
          line-height: calc(1em + 8px);
          margin-bottom: 0;
        }
        blockquote.wp-block-quote cite {
          font-size: var(--font-size-s);
          color: var(--color-dark-green);
          font-style: italic;
        }
        @media screen and (min-width: 768px) {
          blockquote.wp-block-quote.has-text-align-left {
            float: left;
            max-width: 225px;
            margin-right: 24px;
          }
          blockquote.wp-block-quote.has-text-align-right {
            float: right;
            max-width: 225px;
            margin-left: 24px;
          }
        }

        /*
         * pullquote
         */
        .wp-block-pullquote,
        .article-content > blockquote:not(.wp-block-quote) {
          padding: 24px;
          text-align: left;
          color: var(--color-dark-green);
          max-width: 90%;
          margin: 0 auto;
          position: relative;
        }
        .wp-block-pullquote.alignleft {
          float: left;
        }
        .wp-block-pullquote.alignright {
          float: right;
        }
        .wp-block-pullquote.has-background {
          margin: 24px auto;
        }
        .wp-block-pullquote:not(.has-background):after,
        .article-content > blockquote:not(.wp-block-quote):after {
          content: "";
          width: 64px;
          height: 64px;
          display: block;
          position: absolute;
          background-image: url('data:image/svg+xml;utf8,<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http:\/\/www.w3.org\/2000/svg"><path d="M28.2171 20.186C24.6615 21.3437 22.2636 22.708 21.0233 24.2791C19.8656 25.7674 19.2868 27.9587 19.2868 30.8527V31.2248H29.2093V51.4419H8V32.3411C8 27.0491 9.24031 22.7907 11.7209 19.5659C14.2842 16.2584 18.4186 13.7364 24.124 12L28.2171 20.186ZM55.0078 20.186C51.5349 21.3437 49.1783 22.708 47.938 24.2791C46.7804 25.7674 46.2015 27.9587 46.2015 30.8527V31.2248H56V51.4419H34.9147V32.3411C34.9147 27.0491 36.155 22.7907 38.6357 19.5659C41.199 16.2584 45.3333 13.7364 51.0388 12L55.0078 20.186Z" fill="%23ccdad7"/></svg>');
          top: -4px;
          z-index: -1;
          left: -15px;
        }
        .wp-block-pullquote blockquote,
        .wp-block-pullquote.is-style-solid-color blockquote {
          max-width: 100%;
          margin: 0;
        }
        .wp-block-pullquote blockquote p,
        .wp-block-pullquote.is-style-solid-color blockquote p,
        .article-content > blockquote:not(.wp-block-quote) > p {
          font-size: var(--font-size-m);
          font-style: italic;
          margin-bottom: 0;
          line-height: 1.4;
        }
        .wp-block-pullquote .wp-block-pullquote__citation,
        .wp-block-pullquote cite,
        .wp-block-pullquote footer {
          margin-top: 12px;
          font-size: var(--font-size-s);
          display: block;
        }
        .wp-block-leafly-blocks-column .wp-block-pullquote {
          max-width: 100% !important;
        }
        @media screen and (min-width: 768px) {
          .wp-block-pullquote {
            max-width: 80%;
          }
        }

        /*
         * image, image gallery, cover image
         */
        .wp-block-cover,
        .wp-block-gallery,
        .wp-block-image,
        .wp-caption {
          max-width: 100%;
          margin: 16px auto;
        }
        .wp-block-cover.alignleft,
        .wp-block-gallery.alignleft,
        .wp-block-image.alignleft,
        .wp-caption.alignleft,
        img.alignleft {
          margin: 0 24px 0 0;
          float: left;
        }
        .wp-block-cover.alignright,
        .wp-block-gallery.alignright,
        .wp-block-image.alignright,
        .wp-caption.alignright,
        img.alignright {
          margin: 0 0 0 24px;
          float: right;
        }
        .wp-block-image.alignright.is-resized,
        .wp-block-image.alignleft.is-resized,
        .wp-block-image.aligncenter.is-resized {
          width: 50%;
        }
        .wp-block-image .aligncenter img {
          margin: 0 auto;
        }
        .wp-block-image figcaption,
        .wp-block-gallery figcaption,
        #article-content .wp-caption-text {
          color: var(--color-green);
          font-size: var(--font-size-xs);
          line-height: 1.6;
          margin: 4px 0 0 0;
          font-weight: 600;
        }
        .wp-block-gallery {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
        }
        .wp-block-gallery .wp-block-image figcaption {
          justify-content: center;
        }
        #article-content p > img:not([class*="align"]),
        .wp-block-image:not(.is-resized) > a img,
        .wp-block-image:not(.is-resized) > img,
        .wp-block-image > figure:not(.is-resized) img,
        .wp-caption img {
          width: 100%;
        }
        @media (max-width: 700px) {
          .wp-block-image img {
            width: 100%;
          }
          .wp-block-image,
          .wp-block-image > figure,
          .wp-caption,
          img.alignleft,
          img.alignright,
          img.aligncenter {
            width: 100% !important;
            float: none !important;
            margin: 16px auto !important;
          }
        }
        .wp-block-cover,
        .wp-block-cover-image {
          height: auto;
          padding: var(--grid-margin-width);
        }
        .wp-block-cover .wp-block-cover__inner-container > p,
        .wp-block-cover-image .wp-block-cover__inner-container > p {
          margin-bottom: 0;
        }
        .wp-block-cover > .image-container {
          position: absolute;
          width: 100%;
          height: 100%;
        }
        .wp-block-cover > .image-container img {
          object-fit: cover;
          object-position: center;
        }

        /*
         * Wordpress table block and tablepress
         */
        .wp-block-table.alignright {
          float: right;
          margin: 0 0 0 24px;
        }
        .wp-block-table.alignleft {
          float: left;
          margin: 0 24px 0 0;
        }
        .wp-block-table table th,
        .wp-block-table table td {
          padding: 8px;
        }
        .wp-block-table table th {
          text-align: left;
        }
        .tablepress,
        .wp-block-table {
          margin-top: 24px;
          margin-bottom: 24px;
        }
        .tablepress thead,
        .wp-block-table thead {
          font-size: 12px;
        }
        .tablepress tbody,
        .wp-block-table tbody,
        .wp-block-table.is-style-stripes tbody tr:nth-child(odd) {
          font-size: 16px;
          background-color: var(--color-white);
        }
        .tablepress tfoot th,
        .tablepress thead th,
        .wp-block-table tfoot th,
        .wp-block-table thead th {
          background-color: var(--color-leafly-white) !important;
          font-weight: 600 !important;
        }
        .tablepress thead th,
        .tablepress tbody td,
        .tablepress tfoot th,
        .wp-block-table thead th,
        .wp-block-table tbody td,
        .wp-block-table tfoot th {
          border: 1px solid var(--color-light-grey) !important;
        }
        .tablepress u {
          text-decoration: none;
        }
        .tablepress a {
          color: var(--color-green);
        }
        .tablepress a:hover {
          text-decoration: underline;
        }

        /*
         * embed block (twitter, facebook, instagram, linkedin etc)
         */
        .wp-block-embed {
          margin: 24px 0;
        }
        .wp-block-embed.wp-embed-aspect-16-9 {
          position: relative;
          width: 100%;
          height: 0;
          padding-bottom: 56.25%;
          /*16:9*/
        }
        .wp-block-embed.wp-embed-aspect-16-9 iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .wp-block-embed.wp-embed-aspect-16-9 .wp-block-embed__wrapper {
          position: initial;
        }
        .wp-block-embed .twitter-tweet,
        .wp-block-embed iframe,
        .wp-block-embed .embedly-card-hug {
          margin: 0 auto !important;
        }
        .wp-block-embed.is-provider-pinterest iframe {
          width: 100% !important;
          max-height: 600px;
        }
        iframe[src*="linkedin"],
        iframe[src*="facebook"],
        iframe[src*="instagram"] {
          width: 100% !important;
          max-width: 500px !important;
          margin: 24px auto !important;
        }
        iframe[src*="linkedin"] {
          min-height: 500px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 8px;
        }

        /*
         * separator block and hr
         */
        hr {
          color: var(--color-tertiary);
          margin-left: auto;
          margin-right: auto;
          width: 90%;
          margin-top: 24px;
          margin-bottom: 24px;
        }
        .wp-block-separator.is-style-dots {
          line-height: 0;
          height: 0;
          padding: 8px;
        }
        .wp-block-separator.is-style-dots:before {
          font-size: 80px;
          letter-spacing: 16px;
          padding-left: 0;
          line-height: 0;
        }

        /*
         * media-text block
         */
        .wp-block-media-text.has-media-on-the-right
          .wp-block-media-text__content {
          padding: 0;
          padding-right: 8%;
        }
        .wp-block-media-text.has-media-on-the-left
          .wp-block-media-text__content {
          padding: 0;
          padding-left: 8%;
        }
        @media (max-width: 600px) {
          .wp-block-media-text.is-stacked-on-mobile
            .wp-block-media-text__content {
            padding: 0 !important;
          }
        }

        /*
         * Buttons/button block
         */
        .wp-block-buttons {
          margin: 16px 0;
          display: flex;
          gap: var(--grid-gutter-width);
          flex-wrap: wrap;
          align-items: center;
        }
        .wp-block-buttons.is-nowrap {
          flex-wrap: nowrap;
        }
        .wp-block-buttons.is-vertical {
          flex-direction: column;
        }
        .wp-block-buttons.is-content-justification-left {
          justify-content: flex-start;
        }
        .wp-block-buttons.is-content-justification-left.is-vertical {
          align-items: flex-start;
        }
        .wp-block-buttons.is-content-justification-center {
          justify-content: center;
        }
        .wp-block-buttons.is-content-justification-center.is-vertical {
          align-items: center;
        }
        .wp-block-buttons.is-content-justification-right {
          justify-content: flex-end;
        }
        .wp-block-buttons.is-content-justification-right.is-vertical {
          align-items: flex-end;
        }
        .wp-block-buttons.is-content-justification-space-between {
          justify-content: space-between;
        }
        .wp-block-buttons > .wp-block-button.wp-block-button__width-25 {
          width: calc(25% - (var(--grid-gutter-width) * 0.75));
        }
        .wp-block-buttons > .wp-block-button.wp-block-button__width-50 {
          width: calc(50% - (var(--grid-gutter-width) * 0.5));
        }
        .wp-block-buttons > .wp-block-button.wp-block-button__width-75 {
          width: calc(75% - (var(--grid-gutter-width) * 0.25));
        }
        .wp-block-buttons > .wp-block-button.wp-block-button__width-100 {
          width: 100%;
          flex-basis: 100%;
        }
        .wp-block-buttons.is-vertical
          > .wp-block-button.wp-block-button__width-25 {
          width: 25%;
        }
        .wp-block-buttons.is-vertical
          > .wp-block-button.wp-block-button__width-50 {
          width: 50%;
        }
        .wp-block-buttons.is-vertical
          > .wp-block-button.wp-block-button__width-75 {
          width: 75%;
        }
        .wp-block-buttons > .wp-block-button > .wp-block-button__link {
          border-radius: 9999px;
          display: block;
          font-size: var(--font-size-m);
          font-weight: 600;
          text-align: center;
          display: block;
          padding: 12px 24px;
        }
        .wp-block-buttons > .wp-block-button > .wp-block-button__link,
        .wp-block-buttons
          > .wp-block-button.is-style-default
          > .wp-block-button__link,
        .wp-block-buttons
          > .wp-block-button.is-style-primary
          > .wp-block-button__link {
          background-color: var(--color-green);
          color: var(--color-white);
        }
        .wp-block-buttons
          > .wp-block-button.is-style-tertiary
          > .wp-block-button__link {
          background-color: transparent;
          border-color: var(--color-default);
          border-width: 2px;
          color: var(--color-default);
        }

        // page layout
        @media (min-width: 768px) {
          .full-width-sidebar
            #article-content
            .wp-block-leafly-blocks-leafly-background-section
            > .container,
          .full-width-sidebar #news-header-container {
            padding-left: 240px;
          }
        }

        #article-content #dispensaries-section p {
          margin-top: 0;
          font-size: 16px;
        }
      `}</style>
    </>
  );
};

export default NewsGlobalStyles;
