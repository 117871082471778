import NewsAd from "./NewsAd";

export default ({
  index = 1,
  targeting,
  enableLazyLoad,
}: {
  index: number;
  targeting?: Record<string, string | string[] | undefined>;
  enableLazyLoad?: boolean;
}) => (
  <NewsAd
    targeting={targeting}
    unit={`/news/article_${index}`}
    className="gam-rectangular-min-height"
    enableLazyLoad={enableLazyLoad}
    sizes={{
      desktop: [728, 90],
      mobile: [
        [320, 50],
        [300, 250],
      ],
    }}
  />
);
