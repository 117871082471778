import React from "react";

import LinkedInIcon from "components/Icons/share/linkedin.svg";

import ShareButton from "../ShareButton";

type Props = { url: string };

export const LinkedInShareLink: React.FC<React.PropsWithChildren<Props>> = ({
  url,
  children,
}) => {
  const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`;
  return (
    <ShareButton aria-label="Share on linkedin" href={shareUrl}>
      {children}
    </ShareButton>
  );
};

export default (props: Props) => {
  return (
    <LinkedInShareLink {...props}>
      <LinkedInIcon height="16" width="16" />
    </LinkedInShareLink>
  );
};
