const createNewsTargetingObject = (
  slug: string,
  category?: string | null,
  tags?: string[],
) => {
  return {
    "news-category": category ? category : undefined,
    "news-slug": slug,
    "news-tags": tags ? `${tags}` : undefined,
  };
};

export default createNewsTargetingObject;
