import React from "react";

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  React.ButtonHTMLAttributes<HTMLButtonElement> & { "aria-label": string };

const ShareButton: React.FC<Props> = ({
  href,
  onClick,
  "aria-label": ariaLabel,
  children,
  ...otherProps
}) => {
  const ComponentType = href ? "a" : "button";
  const componentProps = href
    ? { href, rel: "noopener noreferrer", target: "_blank" }
    : {};

  return (
    <ComponentType
      onClick={onClick}
      aria-label={ariaLabel}
      className="bg-default rounded-full flex items-center justify-center w-7 h-7"
      {...componentProps}
      {...otherProps}
    >
      {children}
    </ComponentType>
  );
};

export default ShareButton;
