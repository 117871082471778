import React from "react";

import FacebookIcon from "components/Icons/share/facebook.svg";

import ShareButton from "../ShareButton";

type Props = { url: string };
export const FacebookShareLink: React.FC<React.PropsWithChildren<Props>> = ({
  url,
  children,
}) => {
  const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  return (
    <ShareButton aria-label="Share on facebook" href={shareUrl}>
      {children}
    </ShareButton>
  );
};

export default (props: Props) => {
  return (
    <FacebookShareLink {...props}>
      <FacebookIcon height="16" width="16" />
    </FacebookShareLink>
  );
};
