// eslint-disable-next-line no-restricted-imports -- Required to parse complex HTML from WP
import { DOMNode, Element } from "html-react-parser";

export default (domNode: DOMNode, letter: string) => {
  const { attribs, name } = domNode as Element;

  if (name === "link" && attribs?.rel === "canonical") {
    (domNode as Element).attribs.href = `${
      (domNode as Element).attribs.href
    }?letter=${letter}`;
  }
  if (name === "script") {
    // @ts-ignore
    const json = JSON.parse((domNode as Element).children[0].data);

    if (json?.["@graph"] && Array.isArray(json?.["@graph"])) {
      const webPageIndex = json["@graph"].findIndex(
        (item: Record<string, string>) => item["@type"] === "WebPage",
      );

      if (webPageIndex > -1) {
        if (json["@graph"][webPageIndex].url) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][webPageIndex].url = `${json["@graph"][webPageIndex].url}?letter=${letter}`;
        }
        if (json["@graph"][webPageIndex].name) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][webPageIndex].name = json["@graph"][webPageIndex].name.replace(' A ', ` ${letter.toUpperCase()} `);
        }
        if (json["@graph"][webPageIndex].description) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][webPageIndex].description = json["@graph"][webPageIndex].description.replace(' A ', ` ${letter.toUpperCase()} `);
        }
        if (json["@graph"][webPageIndex].primaryImageOf?.["@id"]) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][webPageIndex].primaryImageOf["@id"] = `${json["@graph"][webPageIndex].url}?letter=${letter}#primaryimage`;
        }
        if (json["@graph"][webPageIndex].image?.["@id"]) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][webPageIndex].image["@id"] = `${json["@graph"][webPageIndex].image["@id"]}?letter=${letter}#primaryimage`;
        }
        if (json["@graph"][webPageIndex].breadcrumb?.["@id"]) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][webPageIndex].breadcrumb["@id"] = `${json["@graph"][webPageIndex].breadcrumb["@id"]}?letter=${letter}#breadcrumb`;
        }
        if (json["@graph"][webPageIndex].potentialAction?.["target"]) {
          // eslint-disable-next-line prettier/prettier -- ignoring for readability
          json["@graph"][webPageIndex].potentialAction["target"] = [`${json["@graph"][webPageIndex].potentialAction["target"][0]}?letter=${letter}`];
        }
      }
    }

    const imageObjectIndex = json["@graph"].findIndex(
      (item: Record<string, string>) => item["@type"] === "ImageObject",
    );

    if (imageObjectIndex > -1) {
      if (json["@graph"]?.[1]?.["@type"] === "ImageObject") {
        // eslint-disable-next-line prettier/prettier -- ignoring for readability
        json["@graph"][1]["@id"] = `${json["@graph"][1]["@id"]}?letter=${letter}#primaryimage`;
      }
    }

    // @ts-ignore
    (domNode as Element).children[0].data = JSON.stringify(json);
  }
};
