import NewsAd from "./NewsAd";

export default ({
  targeting,
}: {
  targeting?: Record<string, string | string[] | undefined>;
}) => (
  <NewsAd
    targeting={targeting}
    unit="/billboard"
    className="billboard"
    sizes={{
      desktop: [970, 250],
    }}
  />
);
