// This is used as a polyfill of sorts to cover the old usage of this arrow
// in the table of contents block. Its not optimal to change within wordpress
// because it would require updating each article to get the new version
// of the table of contents block.
export default () => (
  <div
    dangerouslySetInnerHTML={{
      __html: `
        <svg width="0" height="0" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1">
          <symbol id="arrow_drop_down" viewBox="0 0 24 24">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.334 10a.334.334 0 00-.227.578l4.438 3.911a.667.667 0 00.91 0l4.438-3.911a.334.334 0 00-.227-.578H7.334z"></path>
          </symbol>
        </svg>
      `,
    }}
  />
);
