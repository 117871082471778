import React, { useMemo } from "react";
// eslint-disable-next-line no-restricted-imports -- Required to parse complex HTML from WP
import HTMLReactParser from "html-react-parser";
import Head from "next/head";

import { CountryCode } from "custom-types/CountryCode";
import useAlternateUrl from "hooks/useAlternateUrl";
import useDomain from "hooks/useDomain";
import correctMetaForCannabisGlossary from "utils/news/correctMetaForCannabisGlossary";
import correctMetaForPagination from "utils/news/correctMetaForPagination";

/*
 * This helps make sure that the element is unique inside the next.js <Head> component.
 * See: https://nextjs.org/docs/api-reference/next/head
 */
const addKeyPropertyToSeoElement = (element: JSX.Element) => {
  const keyProp =
    element.props?.property || element.props?.name || element.props?.rel;

  if (keyProp) {
    return {
      ...element,
      key: keyProp,
    };
  }

  return element;
};

const addKeyProperties = (elements: React.ReactNode): JSX.Element[] => {
  return Array.isArray(elements)
    ? elements.map(addKeyPropertyToSeoElement)
    : [];
};

export const NewsMeta: React.FC<{
  articleId?: number;
  regionRestriction: CountryCode | "WW";
  seoHeadContent: string;
  title: string;
  currentPage?: number;
  totalPages?: number;
  currentLetter?: string;
}> = ({
  articleId,
  regionRestriction,
  seoHeadContent,
  title,
  currentPage,
  totalPages,
  currentLetter,
}) => {
  const { usUrl, caUrl } = useAlternateUrl(true);
  const domain = useDomain();

  const seoContent = useMemo(
    () =>
      seoHeadContent
        ? addKeyProperties(
            HTMLReactParser(seoHeadContent, {
              replace: (domNode) =>
                currentPage && currentPage !== 1
                  ? correctMetaForPagination(domNode, currentPage)
                  : currentLetter
                    ? correctMetaForCannabisGlossary(domNode, currentLetter)
                    : () => {},
              trim: true,
            }),
          )
        : [],
    [seoHeadContent, currentPage],
  );

  const showUsUrl = regionRestriction !== "CA";
  const showCaUrl = regionRestriction !== "US";

  return (
    <Head>
      {seoContent || (
        <title dangerouslySetInnerHTML={{ __html: title }}></title>
      )}
      {showUsUrl && (
        <link
          key="us-alternate"
          rel="alternate"
          href={`${usUrl}`}
          hrefLang="en-us"
        />
      )}
      {showCaUrl && (
        <link
          key="ca-alternate"
          rel="alternate"
          href={`${caUrl}`}
          hrefLang="en-ca"
        />
      )}
      <link rel="profile" href="http://gmpg.org/xfn/11" />
      <link rel="pingback" href={`${domain}/xmlrpc.php`} />
      {articleId && (
        <link rel="shortlink" href={`${domain}/wp-home/?p=${articleId}`} />
      )}
      {currentPage && (
        <title>{`${title.replace("&amp;", "&")} Archive ${
          currentPage > 1 ? `| Page ${currentPage} of ${totalPages}` : ""
        } | Leafly`}</title>
      )}
    </Head>
  );
};
