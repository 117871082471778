import NewsAd from "./NewsAd";

export default ({
  targeting,
  enableLazyLoad,
}: {
  targeting?: Record<string, string | string[] | undefined>;
  enableLazyLoad?: boolean;
}) => (
  <NewsAd
    targeting={targeting}
    unit="/news/news_lower"
    className="lower"
    sizes={{
      desktop: [728, 90],
      mobile: [
        [320, 50],
        [300, 250],
      ],
    }}
    enableLazyLoad={enableLazyLoad}
  />
);
