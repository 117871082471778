import NewsAd from "./NewsAd";

export default ({
  targeting,
}: {
  targeting?: Record<string, string | string[] | undefined>;
}) => (
  <NewsAd
    targeting={targeting}
    unit="/news/skyscraper"
    className="sidebar"
    sizes={{
      desktop: [160, 600],
    }}
  />
);
