import classNames from "classnames";

import GoogleAd from "components/GoogleAd";

type AdSizes = {
  desktop?: googletag.GeneralSize;
  mobile?: googletag.GeneralSize;
};

type Props = {
  unit: string;
  className: string;
  sizes: AdSizes;
  targeting?: Record<string, string | string[] | undefined>;
  enableLazyLoad?: boolean;
};

export default ({
  unit,
  className,
  sizes,
  targeting,
  enableLazyLoad,
}: Props) => {
  return (
    <GoogleAd
      adPath={`/13212770${unit}`}
      className={classNames(
        "leafly-gam-news-container mx-auto my-6",
        className,
      )}
      sizes={sizes}
      targeting={targeting}
      enableLazyLoad={enableLazyLoad}
    />
  );
};
