import React, { useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import { MenuItems } from "custom-types/Wordpress";
import { useEventTracker } from "hooks/useEventTracker";

import SolidArrowIcon from "components/Icons/arrows/SolidArrowIcon";

import NewsAdSkyscraper from "../Ads/NewsAdSkyscraper";

type Props = {
  menuItems?: MenuItems;
  disableAds?: boolean;
  optionLabel?: string;
  adTargeting?: Record<string, string | string[] | undefined>;
};

const NewsSideBar: React.FC<Props> = ({
  menuItems = [],
  disableAds = false,
  optionLabel = "Explore more topics",
  adTargeting,
}) => {
  const { query } = useRouter();
  const { publishEvent } = useEventTracker();
  const [isOpen, setIsOpen] = useState(false);

  const trackNavigation = (label: string | undefined) => {
    setIsOpen(false);

    label &&
      publishEvent({
        action: "Navigation",
        category: "News V2",
        label,
      });
  };

  const slugs = query?.slugs
    ? typeof query.slugs === "string"
      ? [query.slugs]
      : query.slugs
    : [];

  const activeSlug =
    query.categorySlug ||
    query.termSlug ||
    [...menuItems]
      .reverse()
      .find((item) => [...slugs].reverse().find((slug) => slug === item.slug))
      ?.slug ||
    "news";

  return (
    <div className="relative">
      <button
        className="md:hidden flex justify-between w-full p-sm bg-leafly-white border border-light-grey mb-xl"
        onClick={() => setIsOpen(!isOpen)}
        aria-label={optionLabel}
      >
        <span>{optionLabel}</span>
        <SolidArrowIcon
          height="24"
          width="24"
          direction={isOpen ? "up" : "down"}
        />
      </button>
      <ul
        className={classNames(
          "z-[2] w-full absolute md:relative max-h-[270px] md:max-h-full -mt-xl md:mt-0 mb-xl md:mb-xxl overflow-y-auto md:overflow-y-visible shadow md:shadow-none bg-white md:bg-transparent",
          {
            "h-0 md:h-auto": !isOpen,
            "h-auto py-sm md:py-0": isOpen,
          },
        )}
        data-testid="article-sidebar"
      >
        {menuItems.map((item) => (
          <li
            className={classNames("relative", {
              "font-bold active": item.slug === activeSlug,
            })}
            key={`news-${item.slug}`}
          >
            <Link
              className="block text-sm md:text-md px-lg py-sm md:px-0 md:py-xs"
              dangerouslySetInnerHTML={{ __html: item.title }}
              href={item.url}
              onClick={() => trackNavigation(item.title)}
            />
          </li>
        ))}
        <style jsx>{`
          li.active:before {
            content: "";
            position: absolute;
            background-color: var(--color-green);
            height: 100%;
            width: 4px;
            left: -12px;
          }
        `}</style>
      </ul>

      {!disableAds && (
        <div className="hidden md:block">
          <NewsAdSkyscraper targeting={adTargeting} />
        </div>
      )}
    </div>
  );
};

export default NewsSideBar;
