import NewsAd from "./NewsAd";

export default ({
  targeting,
}: {
  targeting?: Record<string, string | string[] | undefined>;
}) => (
  <NewsAd
    targeting={targeting}
    unit="/news/news_upper"
    className="gam-rectangular-min-height"
    sizes={{
      desktop: [728, 90],
      mobile: [
        [320, 50],
        [300, 250],
      ],
    }}
  />
);
